import { queryMenus, getMenu } from '@wix/ambassador-restaurants-menus-v1-menu/http';
import type { Menu } from 'root/apiTypes';
import type { HttpClientFactory } from './utils/types';

export const MenusClient: HttpClientFactory<Menu> = (httpClient) => ({
  get: ({ id }) => {
    return httpClient
      .request(getMenu({ menuId: id }))
      .then((res) => {
        const {
          data: { menu = {} },
        } = res;

        return { data: menu as Menu };
      })
      .catch((e) => {
        throw new Error(`Error when calling getMenu with id ${id}- ${e}`);
      });
  },
  getAll: ({ paging, filter = { visible: true } }) => {
    return httpClient
      .request(queryMenus({ query: { cursorPaging: paging, filter, sort: [{ fieldName: 'createdDate' }] } }))
      .then((res) => {
        const {
          data: { menus, pagingMetadata },
        } = res;

        return { data: menus as Menu[], pagingMetadata };
      })
      .catch((e) => {
        throw new Error(`Error when calling getMenus- ${e}`);
      });
  },
});
